/* ========================================================================
   Component: datepicker
 ========================================================================== */

.ui-datepicker {
  display: inline-block;
  max-width: 100%;
  overflow: auto;
  background-color: #fff;
  table {
    width: 100%;
  }
  button {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 0 #000 !important;

    &.active {
      background-color: $brand-purple !important;
      > span {
        color: #fff !important;
      }
    }

    .text-info {
      color: $brand-purple;
      border-bottom: 1px solid;
    }
  }
}

.daterangepicker .input-mini.active {
    border-color: $brand-green-strider !important;
}

.daterangepicker td.in-range {
    background-color: lighten($brand-green-strider, 44) !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $brand-green-strider !important;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #fff !important;
    color: #999 !important;
}

