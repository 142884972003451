/*!
 *
 * Angle - Bootstrap Admin App + AngularJS Material
 *
 * Version: 3.5.2
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */


/* [Table of contents]

1. Bootstrap reset
  1.1 bootstrap
  1.2 bootstrap-reset

2. Typography
  2.1 typo

3. Layout
  3.1 layout / .wrapper .content-wrapper
  3.2 top-navbar / .top-navbar
  3.3 sidebar / .sidebar
  3.4 offsidebar / .offsidebar
  3.5 preloader / .preloader

4. Components
  4.1  button-extra
  4.2  placeholder / .box-placeholder
  4.3  panels
  4.4  progress-extra
  4.5  radial-bar / .radial-bar
  4.6  user-block / .user-block
  4.7  circles / .circle
  4.8  timeline / .timeline
  4.9  dropdown-extra
  4.10 row-extra / .row-table .row-flush
  4.11 half-float / .half-float
  4.12 masonry-grid / .row-masonry
  4.13 widget / .widget
  4.14 typeahead / .typeahead-ctrl
  4.15 animate
  4.16 loading-bar
  4.17 table-grid
  4.18 todo / .todo
  4.19 ngwig
  4.20 ngDialog
  4.21 nestable
  4.22 carousel
  4.23 masonry-grid-deck

5. Charts
  5.1 chart-flot / .flot-chart
  5.2 chart-easypie / .easypie-chart

6. Form elements
  6.1 form-elements
  6.2 form-imgcrop
  6.3 form-validation
  6.4 form-wizard
  6.5 form-tags
  6.6 uiselect
  6.7 code-editor

7. Tables
  7.1 datatable
  7.2 table-extras
  7.3 table-ngtable
  7.4 angular grid

8. Plugins
  8.1 plugins
  8.2 slim-scroll / .slimScrollBar
  8.3 datepicker / .bootstrap-datetimepicker-widget
  8.4 alerts / .alerts
  8.5 notifiy / .notifiy
  8.6 calendar / .fc-*
  8.7 spinner / .whirl
  8.8 gmap / .gmap
  8.9 vector-map / vector-map
  8.10 portlets / .portlet

9. Utilities
  9.1 utils

10. Print CSS
  10.1 print

11. Settings
  11.1 settings

12. Documentation
  12.1 docs

13. Material

 */
// Custom
@import "app/popup";
@import "app/angular-material-reset";
@import "app/material/colors";

// Override bootstrap variables
@import "app/variables";

// Bootstrap
@import "bootstrap/bootstrap/_mixins";

// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";

// Bootstrap reset
@import "app/bootstrap-reset";

// Typography
@import "app/typo";

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/registers";

//Custom
@import "app/strider";
@import "app/home";
@import "app/pages";
@import "app/dashboard-client";
@import "app/top-navbar-home";
@import "app/calendar-mini";
@import "app/report";
@import "app/wizard";
@import "app/budget";
@import "app/base-general";
@import "app/base-widgets";
@import "app/password-strength";
@import "app/area";
@import "app/season";
@import "app/farmshots";
@import "app/purchase-orders";

// Components
@import "app/preloader";
@import "app/breadcrumbs";
@import "app/loading-bar";
@import "app/animate";
@import "app/button-extra";
@import "app/placeholder";
@import "app/panels";
@import "app/progress-extra";
@import "app/radial-bar";
@import "app/user-block";
@import "app/circles";
@import "app/timeline";
@import "app/dropdown-extra";
@import "app/row-extra";
@import "app/half-float";
@import "app/masonry-grid";
@import "app/widget";
@import "app/typeahead";
@import "app/table-grid";
@import "app/todo";
@import "app/ngwig";
@import "app/ngdialog";
@import "app/nestable";
@import "app/carousel";
@import "app/masonry-grid-deck";

// Charts
@import "app/chart-flot";
@import "app/chart-easypie";

// Form elements
@import "app/form-elements";
@import "app/form-imgcrop";
@import "app/form-validation";
@import "app/form-tags";
@import "app/form-wizard";
@import "app/uiselect";
@import "app/code-editor";

// Tables
@import "app/datatable";
@import "app/table-extras";
@import "app/table-ngtable";
@import "app/table-uigrid";
@import "app/table-angulargrid";

// Plugins
@import "app/plugins";
@import "app/slim-scroll";
@import "app/datepicker";
@import "app/alerts";
@import "app/notify";
@import "app/calendar";
@import "app/spinner";
@import "app/gmap";
@import "app/vector-map";
@import "app/portlets";

// Mailbox
@import "app/mailbox";

// Plans
@import "app/plans";

// Utilities
@import "app/utils";

// Print CSS
@import "app/print";

// Settings
@import "app/settings";

// Documentation
@import "app/docs";

// Material
@import "app/material/colors";
@import "app/material/welcome";
@import "app/material/cards";
@import "app/material/md-forms";
@import "app/material/md-list";
@import "app/material/md-inputs";
@import "app/material/ngmaterial";


