$border-radius-report: 10px;
$btn-group-bg-color: #C3C8D2;
$btn-group-bg-color-darker: darken($btn-group-bg-color, 10);
$btn-group-text-color: #ffffff;

.icon-float-left {
    float: left;
    margin-right: $margin-sm !important;
}

.report-container {
    margin-top: -$margin-xl;
}

.menu-btn-group {
    position: absolute;
    margin-top: -20px;
    width: 800px;
    margin-left: 85px;
}


.btn-report {
    background: $btn-group-bg-color;
    color: $btn-group-text-color;

    input {
        height: 17px;
        color: $btn-group-text-color;
        background-color: rgba(255, 255, 255, 0);
        border: 0 none;
        cursor: pointer;
        font-size: 13px;
    }
    input:hover, input:focus, input:active {
        background: rgba(255, 255, 255, 0);
        color: $btn-group-text-color;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
    }
}

.btn-report:hover, .btn-report:focus, .btn-report:active, .btn-report.active, .open > .dropdown-toggle.btn-report {
    background: $btn-group-bg-color-darker;
    color: $btn-group-text-color;
}

.btn-report:active, .btn-report.active {
    background: $brand-green-strider;
    box-shadow: none;
}

table.md-table td.md-cell {
    color: $font-color-gray-dark !important;
}

table.md-table th.md-column {
    color: $font-color-gray-dark !important;
    font-weight: bold !important;
}

md-table-pagination .label {
    color: $font-color-gray-dark !important;
    font-size: 11px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2), table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: 0 36px 0 0 !important;
}

.report-daily-input-date {
    border-radius: 5px;
    color: #A3A8AC;
}

.md-column, .md-cell {
    text-align: center !important;
}

.alert-speed {
    color: #FF4E20;
}
