.strider-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
}

.strider-modal {
    position: absolute;
    top: 240px;
    left: 10%;
    width: 80%;
    min-height: 200px;
    background: transparent;
    z-index: 9999;
    border-radius: 10px;
    padding: 20px;
}

.div-disabled{
  opacity: 0.5;
  pointer-events: none;

  > * {
    opacity: 0.5;
    pointer-events: none;
  }
}

.phase-selected {
    background-color: rgba(251, 248, 213, 0.40);
}