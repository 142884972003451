/* ========================================================================
     Component: print
 ========================================================================== */

// 
// Put here all rules required to style the print view 
// 

@media print {
  
  // .sidebar,
  // .topnavbar,
  // .offsidebar,
  // .btn {
  //   display: none !important;
  //   width: 0 !important;
  //   height: 0 !important;
  // }

  // .wrapper,
  // .wrapper > section,
  // .content-wrapper {
  //   margin: 0 !important; /* remove margin used for sidebar and expand the content */
  //   padding: 0 !important;
  //   width: 100% !important;
  // }

  
  // .content-wrapper {
  //   overflow: hidden !important;
  // }

  body * {
      visibility: hidden;
  }

  md-dialog {
      visibility: visible;
      overflow: visible !important;
      width: 100% !important;

      position:absolute;
      top:0px;
      bottom:0px;
      margin: auto;
      margin-top: 0px !important;

      panel-body {
        border-bottom: inherit !important;
      }
  }

  .md-dialog-container {
     overflow: visible !important;
  }

  md-dialog-content, .md-dialog-content, .panel, .table {
      width: 100% !important;
      max-width: 100% !important;
      
  }

  .print-content * {
      visibility: visible;
      overflow: visible;
  }

  li {
      page-break-after: auto;
  }
  .onPrint {
      display:none;
  }
}