md-content:focus {
outline: none;
border: 0;
}
.session-selected {
background: #b9f6ca;
}
.session-inactive {
background: transparent !important;
}
.session-selected:hover {
background-color: #9c9 !important;
}
.asset-pin {
font-size: large;
padding: 7px;
padding-top: 10px;
width: 36px !important;
height: 36px;
border-radius: 100%;
}
.vehicle {
/* background-color: #f0f0f0;
border-radius: 50%;
width: 40px;
height: 40px;
margin-right: 16px;
box-sizing: content-box;
padding: 5px;
*/
//line-height: 36px;
//min-width: 0;
//width: 60px;
//height: 60px;
//vertical-align: middle;
//box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
//border-radius: 50%;
//background-clip: padding-box;
//overflow: hidden;
//transition: .2s linear;
//transition-property: background-color, box-shadow;
}
//.active {
//animation: greenPulse 2s linear infinite;
//}
@keyframes greenPulse {
from {
transform: scale(1);
-webkit-box-shadow: 0 0 9px #fff;
}
50% {
transform: scale(1.05);
-webkit-box-shadow: 0 0 18px #91bd09;
}
to {
transform: scale(1);
-webkit-box-shadow: 0 0 9px #fff;
}
}
.st-greenyellow {
background-color: #03B500 !important;
}
.st-gray {
background-color: #5C5F5C !important;
}
.animate-hide {
transition: all ease-in-out 0.5s;
max-height: 1000px;
opacity: 1;
}
.animate-hide.ng-hide {
max-height: 0px;
opacity: 0;
}

.tracker {
    height: 54px;
    width: 54px;
}

.fixed-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 25%;
    display:table;
    vertical-align:middle;
    text-align:center;
}

.scroll-initial {
    overflow-y: auto !important;
    max-height: 90vh !important;
}

.scroll-current-vehicle {
    overflow-y: auto !important;
    max-height: 79vh !important;
}

.box-player {
    margin-top: -10.05px;
    border-radius: 5px;
}

.md-button.md-fab.md-player {
    line-height: 30px;
    width: 30px;
    height: 30px;
    min-height: 0px;
    background-color: #fff;
//    background-color: #FFF;
}

.md-button.md-fab.md-player:not([disabled]):hover {
    background-color: $brand-green-strider-light;
}

.md-button.md-default-theme.md-fab:not([disabled]).md-focused, .md-button.md-fab:not([disabled]).md-focused {
    background-color: #fff;
}

.md-track.md-track-fill, .md-thumb {
    background-color: $brand-green-strider-light;
}

.desc-player {
    margin-top: -15px;
}
