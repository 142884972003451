$bg-calendar-color: $gray-dark;
$border-radius: 10px;
$border-radius-top: 7px;
$border-radius-top: $border-radius-top $border-radius-top 0 0;
$border-radius-bottom: 0 0 $border-radius $border-radius;

.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
calendar {
  float: left;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: white;
  width: 100%;
  border: solid 1px #CCC;
  margin-bottom: 10px;
  border-radius: $border-radius;
}
calendar > div.header {
  float: left;
  width: 100%;
  background: $bg-calendar-color;
  height: 40px;
  color: white;
  border-radius: $border-radius-top;
}
calendar > div.header > * {
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  vertical-align: middle;
}
calendar > div.header > i {
  float: left;
  width: 40px;
  font-size: 1.125em;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
}
calendar > div.header > i.fa-angle-left {
  text-align: left;
}
calendar > div.header > i.fa-angle-right {
  text-align: right;
  margin-left: -40px;
}
calendar > div.header > span {
  float: left;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding-left: 50px;
  margin-left: -40px;
  text-align: center;
  padding-right: 40px;
  color: inherit;
}
calendar > div.week {
  float: left;
  width: 100%;
  border-radius: $border-radius-bottom;
}
calendar > div.week:first-child {
  border-top: none;
}
calendar > div.week > span.day {
  float: left;
  width: 14.28571429%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 0.75em;
  text-align: center;
  height: 30px;
  line-height: 30px !important;
  display: inline-block;
  vertical-align: middle;
  background: white;
  cursor: pointer;
  color: $font-color-gray;
  border-radius: $border-radius;
}
calendar > div.week > span.day:first-child {
  border-left: none;
}
calendar > div.week > span.day.today {
  font-weight: bold;
  color: $brand-green-strider;
}
calendar > div.week > span.day.different-month {
  color: #C0C0C0;
}
calendar > div.week > span.day.selected {
  @include bgcolor-variant('success');
  color: white;
}

.dateInvalid {
    div.week > span.day.selected {
      @include bgcolor-variant('danger');
      color: white;
    }
}

calendar > div.week.names > span {
  color: $bg-calendar-color;
  font-weight: bold;
}
