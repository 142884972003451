.full-map{
    height: 96%; 
    margin: -15px -15px 0px -15px;
}

.property-container {
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0px;
    width: 350px;
    padding: 0;
    margin: 0;
    z-index: 999;
    pointer-events: none;
    background:#FFF;
  &.property-container-areas{
    .panel{
      border:none;
      box-shadow:none;
    }
    .panel-footer{
      background:none;
    }
    .panel-heading{
      padding:0;
      margin-top:65px;
      .title{
        margin-top:78px;
        margin-bottom:0;
        border-bottom:2px solid $mdc-green-A500;
        color:$mdc-grey-750;
        font-size:14px;
        padding:20px 30px 30px;
      }
      .btn-new-area{
        width: 100%;
        padding: 20px 20px 20px 20px;
        border: none;
        border-bottom: 2px solid $mdc-green-A700;
        text-align: left;
        font-size: 14px;
        color: $mdc-grey-750;
        font-weight:bold;
        .fa{
          float: right;
          font-size: 22px;
          color:$mdc-green-A500;
        }
      }
    }
    .panel-body{
      &.area-edit{
        margin-top: -25px;
        background:#F6F2C1;
        padding:0;
      }
    }
    .panel-footer{
      .btn{
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        padding:12px 30px;
        &.btn-success{
          background-color: #099356;
        }
      }
    }
    .close-area{
      color: $mdc-grey-750;
      font-size:16px;
      cursor:pointer;
      position:relative;
      z-index:100;
    }
    &.crop-areas{
      .panel-heading{
        margin-top: 15px;
        padding: 10px;
        margin-bottom: 15px;
      }
    }
  }
}
.panel-footer-fixed{
  position: absolute;
  bottom: 0;
  width: 100%;
  .btn-new-area{
    width: 100%;
    padding: 20px 20px 20px 20px;
    border: none;
    border-top: 2px solid $mdc-green-A700;
    text-align: left;
    font-size: 14px;
    color: $mdc-grey-750;
    font-weight:bold;
    .fa{
      float: right;
      font-size: 22px;
      color:$mdc-green-A500;
    }
  }
}
.tree-node-areas{
  .btn{
    border:none;
    .fa-pencil-square-o{
      color:$mdc-green-A700;
      font-size:18px;
    }
    &.btn-arrow{
      color:$mdc-green-A700;
      background:none;
      text-align:left;
    }
  }
  .tree-node-item{
    margin:10px 0;
  }
  .angular-ui-tree-nodes{
    padding-left:15px;
  }
}
.tree-node-area{
  margin:10px 0;
}
.property-menu {
    position: absolute;
    top:0;
    border-radius: 5px;
    background-color: white;
    padding: 0;
    margin:0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: auto;
    .md-autocomplete-suggestions li {
      height: 420px;
      max-height: 420px !important;
    }
}

.season-menu {
    position: relative !important;
    top: 2.5%;
    border-radius: 5px;
    background-color: white;
    padding: 0;
    margin: 0% 5%;
    max-height: 95%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: auto;
}

.menu-items {
  background-color: $sidebar-bg;

  .nav-heading {
    color: $sidebar-heading-color;
  }

  // Items
  .nav {
    > li {
      > a,
      > .nav-item {
        color: $sidebar-item-color;
        &:focus, &:hover {
          color: $sidebar-item-color-active;
        }
        // Item icon
        > em {
          color: $sidebar-icon-color;
        }
      }

      // Active item state
      &.active,
      &.open {
        &,
        > a,
        .nav {
          background-color: $sidebar-item-bg-active;
          color: $sidebar-item-color-active;
        }
        > a > em {
          color: $sidebar-icon-color-active;
        }
      }
      &.active {
        border-left-color: $sidebar-item-color-active;
      }
    }
  }
}

.leaflet-left {
    left: 370px !important;
    top: 5px !important;
}
.details-area {
    padding: 10px 15px;
}

.leaflet-instruction {
    height: 130px;
    position: absolute;
    top: 160px;
    left: 15px;
    bottom: 0px;
    width: 300px;
    padding: 0;
}
.tooltip-draw{
  position:relative;
  z-index:1000;
  .well{
    position: absolute;
    top: 20px;
    left: 420px;
    z-index: 200;
    max-width: 200px;
    border-radius: 10px;color: #000;
  }
  .arrow{
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right:10px solid #FFF;
    position:absolute;
    top: 77px;
    left: -10px;
  }
  .arrow-top-upload{
    top: 120px !important;
  }
}
.tooltip-draw-season-areas{
  z-index: 1000;
  position: absolute;
  left: 0px;
  top: 70px;
  .well{
    position: absolute;
    top: 70px;
    left: 380px;
    z-index: 200;
    width: 170px;
    border-radius: 10px;
    color: #000;
  }
  .arrow{
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #FFF;
    position: absolute;
    top: 32px;
    left: -10px;
  }
}


.area-map{
  .leaflet-div-icon{
    background-color:#D2870A;
    border-radius:100px;
  }
}
.btn-edit-region{
  font-size: 10px;
  padding: 3px 5px;
  line-height: 7px;
  height: 18px;
  position: relative;
  left: 5px;
}

.header-upload {
  margin-top: 45px !important;
}