.farmshots-iframe{
    width:100%; 
    height:100%; 
    border:none; 
    overflow:hidden;
}

.farmshots-container-map{
    background-color: #fafbfc;
    overflow:hidden;
    position:absolute; 
    top:0px; 
    left:0px; 
    bottom:0px; 
    right:0px; 
    width:100%; 
    height:100%;
    margin:0; 
    padding:0;  
}
.bar-top-filters{
    height: 11px;
    background-color: rgb(6, 128, 69);
    color: rgba(255, 255, 255, 0.87);
}
.farmshots-container-map > .sidebar-left{
    top: 65px;
    left: 0px;
    width: 300px;
    height: 100%;
    position: absolute;
    .filters {
        background-color: #ffff;
        .repeated-item {
            border-bottom: 1px solid #ddd;
            box-sizing: border-box;
            padding: 5px 0;
            height: 60px;
            border-left: 3px solid transparent;
            padding: 8px;
        }
        .active {
            background-color: #fcfcfc;
            color: #099356;
            border-left-color: #099356;
        }
    }
    .tab-div{
        width: 22px;
        min-width: 22px;
        #sidebar-tab {
            position: absolute;
            top: 42%;
            width: 20px;
            border: 2px solid #fff;
            border-left: none;
            border-radius: 0 25px 25px 0;
            background-color: rgb(6, 128, 69);
            color: rgba(255, 255, 255, 0.87);
            height: 40px;
            i{
                margin-top: 11px;
                margin-left: 1px;
            }
        }
    }
}
.side-bar-none{
    width: 22px !important;
}