.img-bg-wizard {

    width: 100%;
    .background-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url(../../../app/img/bg-wizard.jpg);
        opacity: 1;
        background-position: 50% 50%;
        background-size: cover;
        position: fixed;
    }
}

h3.welcome {
    padding: 20px;
    font-size: 32px;

    small {
        margin-top: 10px;
        font-size: 18px;
    }
}
.container-wizard{
    max-width:600px;
    &.login{
        max-width:450px;
    }
}
.panel.panel-default.panel-wizard {
    counter-reset: wizard-items-counter;
    border:none;
    box-shadow:none;
    background:none;
    
    .panel-body {
        padding: 0;

        .base-wizard-step {
            padding: 30px;
            background-color: #ffffff;
            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.17);

            h3 {
                margin-bottom: 20px;
            }

            table {
                color: #929292;
                
                a.wizard-action {
                    color: #f05050;
                    margin-right: 10px;
                    
                    &:hover {
                        color: #ec2121;
                    }
                }
            }
        }

        .form-wizard > ol {
            padding: 0;

            > li {
                padding: 15px;

                &:hover {
                    background-color: rgba(0, 0, 0, .1) !important;
                }
                &.active {
                    background-color: #099356 !important;
                }
                h4 {
                    counter-increment: wizard-items-counter;
                    margin: 0;

                    &:before {
                        content: counter(wizard-items-counter) '. ';
                        font-size: 28px;
                    }
                }   
            }
        }
    }
    .wizard-divider-header{
        width: 1920px;
        margin-left: -960px;
        position: absolute;
        left: 50%;
        top: 45px;
    }
    md-input-container{
        .md-input{
            border-color:#979797;
        }
    }
    md-input-container label {
        color: $mdc-green-A500;
    }
    md-input-container.md-input-focused label {
        color: $mdc-green-A500;
    }
    md-input-container.md-input-invalid label {
        color: #ec2121;
    }
    md-select-value{
        color: $mdc-green-A500;
        padding-bottom:10px;
        .md-select-icon{
            font: normal normal normal 14px/1 FontAwesome;
            &:after {
                content:"\f078";
                display:inline;
                font-size:24px;
            }
        }
    }
    .md-select-country{
        .md-select-icon{
            display:none;
        }
    }
    .btn-success{
        background-color: #099356;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 20px;
        padding: 15px 40px;
    }
    .btn-default{
        background-color: transparent;
        color:#099356;
        font-weight: bold;
        font-size: 20px;
        padding: 15px 80px;
    }
    .crop-step{
        position:relative;
        .md-datepicker-button{
            display:none;
        }
        .md-datepicker-input-container{
            margin-left:0;
            .md-datepicker-triangle-button{
                border-radius:0;
            }
        }
        .md-datepicker-expand-triangle{
            font: normal normal normal 14px/1 FontAwesome;
            position: absolute;
            top: -3px;
            left: 0%;
            &:after{
                content:"\f073";
                display:inline;
                font-size:24px;
                color:$mdc-green-A500;
            }
        }
        ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore){
            right:0;
            left:0;
        }
        md-datepicker{
            cursor:pointer;
        }

    }
    .last-step-bar{
        min-height:75px;
        background-color:#D8D8D8;
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
        padding-top:24px;
        p{
            font-size:18px;
        }
    }
    .login-info{
        padding-top:25px;
        font-size:17px;
        a{
            margin-left:10px;
            font-weight:bold;
        }
    }
    md-checkbox.md-checked .md-icon{
        background-color:$mdc-green-A800;
    }
    .checkbox-terms{
        p{
            font-size: 18px;
            margin: 5px;
            a{
                font-weight:bold;
                margin-left:5px;
            }
        }
    }
}
// md-dialog .md-dialog-content{
//     max-width:600px;
// }
#wizard-property-map {
    height: 300px;
}
.wrapper-wizard{
    padding-left: 15px;
    padding-right: 15px;
}
.header-steps{
    padding-top:4px;
    .md-button{
        &.md-fab{
            &.md-mini{
                max-width: 25px;
                min-width: 25px;
                max-height: 25px;
                min-height: 25px;
                background: none;
                color:$mdc-grey-750;
                box-shadow: none;
                &:hover{
                    color:#FFFFFF;
                }
                p{
                    line-height:25px;
                }
            }
        }
    }
    li{
        &.active{
            .md-button {
                &.md-fab {
                    &.md-mini {
                        background-color:$mdc-green-A800;
                        color:#FFFFFF;
                    }
                }
            }
        }
    }
    ol{
        margin-left:-35px;
    }
}
.wizard-tips-container{
    min-height:160px;
    h2{
        color:$mdc-grey-750;
        font-size:24px;
    }
    p{
        font-size:16px;
        color:$mdc-grey-750;
    }
    .wizard-tip{
        padding:0 50px;
        max-width:540px;
    }
}
.property-map-wrapper{
    min-height:350px;
    position:relative;
    .property-map-container{
        position: absolute;
        width: 100%;
    }
}
.user-data-confirmation{
    min-height:700px;
    background-color:#D8D8D8;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    padding:25px 25px 80px;
    margin-bottom:50px;

    .title{
        font-size:18px;
        color:$mdc-grey-750;
        font-weight:bold;
        margin:40px 0 5px 0;
    }
    p{
        font-size:14px;
        color:$mdc-green-A800;
        margin-bottom:0;
    }
    span{
        font-size:18px;
        color:$mdc-grey-750;
    }
    .container{
        max-width: 980px;
        padding-left: 100px;
    }
}
.tooltip-wizard{
    background-color:#FFFFFF !important;
    color:#000 !important;
    font-size:14px !important;
    padding:15px !important;
    .md-content{
        background-color:#FFFFFF !important;
    }
}
.md-wizard-tooltip{
    width:30px !important;
    height:30px !important;
    min-width:30px !important;
    min-height:30px !important;
    background-color:$mdc-green-A500 !important;
    line-height: 11px !important;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -13px;
    &.crop{
        right: 11px;
        top: 80px;
    }
    &.region{
        right: 11px;
        top: 8px;
    }
}
.crop-active{
    background-color:rgb(238,238,238);
}
.box-wizard{
    z-index: 9999999
}

.stripe-wizard{
    position: fixed !important;
    width: auto !important;
    height: 150% !important;
    z-index: 0;
}
.wrapper-landing {
    height: auto !important;
}
