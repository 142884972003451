.btn-statement {
    background-color: rgba(9, 147, 86, 0.1);
    color: #515253;
    
}
.statement-disable {
    background-color: #dfe1e4 !important;
}
.products {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    
    .title {
        font-size: 16px;
        font-weight: 500;
        color: #515253;
    }
    .current-balance-container {
        margin-top: 18px;
        .current-balance {
            font-size: 14px;
            font-weight: 500;
            color: #707374;
        }
        .current-balance-price {
            font-size: 13px;
            font-weight: 500;
            color: #099356;
        }
    }
    
}
.products-tab {
    margin-left: 50px;
}