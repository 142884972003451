.close-popup {
    position: fixed;
    top: -15px;
    right: -23px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
}

md-dialog {
    overflow: visible !important;
}
.panel-overflow {
    overflow: auto !important;
}

.chosen-results, .chosen-drop {
    height: 100px;
}
