/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */



[ng\:cloak], 
[ng-cloak], 
[data-ng-cloak], 
[x-ng-cloak], 
.ng-cloak, 
.x-ng-cloak {
  display: none !important;
}


//
// Use this file to reset simple bootstrap styles
// instead of modifying the core files
// Makes easy to update new framework versions
// --------------------------------------------------

*:focus {
  outline: 0 !important;
}

a {
  outline: none !important;
  cursor: pointer;
  color: $brand-green-strider;
  &.text-muted {
    &:hover, &:focus {
      color: darken($text-muted, 10%);
    }
  }
}

[ng-click], [data-ng-click] {
  cursor: pointer
}

hr {
  border-top: 1px solid $gray-light;
}

// custom element
now {
  display: inline-block;
}

.navbar-toggle {
  .icon-bar {
    background-color: #fff;
  }
}

.list-group {
  line-height: 1.3;
  .list-group-item {
    padding: 10px;
    .panel > & {
      border-top: 0;
    }
  }

}

.page-header {
  border-bottom-color: $gray-light;
}

.panel {
  .panel-heading {
    border-bottom: 0;
    font-size: 14px;
    a {
      text-decoration: none !important;
    }
  }
  &.panel-default {
    margin-top: 0px !important;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border: none;
  }
}

.well {
  border: 0;
  border-radius: 0;
  @include material-shadow(1);
}

.jumbotron {
  border: 1px solid $gray;
  @media only screen and (min-width: $mq-tablet) {
    padding: $jumbotron-padding ($jumbotron-padding/2);
  }
}

.nav-tabs > li > a {
  font-weight: bold;
  color: $text-color;
  background-color: $gray-lighter;
  margin: 0;
  border: 1px solid $gray;
  border-radius: 0;
  padding: 10px 20px;
}

.nav-tabs > li.active > a {
  &, &:hover, &:focus {
    color: inherit;
    border-bottom-color: #fff;
  }
}
.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: $gray;
  .nav-pills + & {
    border: 0;
    padding: 0;
  }
}
// quick reset padding using tabset.p0
.p0 .tab-content {
  padding: 0 !important;
}



.btn {
  border-radius: 3px;
  font-size: 13px;
  border-color: transparent;
  -webkit-appearance: none;
  outline: none !important;
  @include transition(all .1s);
  &.btn-link {
    box-shadow: none;
    border: 0;
  }
  &.btn-default {
    border-color: $btn-default-border;
  }
  .input-group & {
    font-size: 14px;
    border-color: $input-border;
  }
  .input-group .input-sm + .input-group-btn & {
    font-size: 13px;
  }
}

// FORMS

.form-control {
  box-shadow: 0 0 0 #000 !important;
  border-radius: 1px;
  border-color: #cfdbe2;
  &:focus {
    border-color: #B0BEC5;
  }
}

.input-sm,
select.input-sm {
  height: 31px;
}

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
  &.last-child,
  &:last-child {
    border-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: $mq-up-to-tablet) {
  // Remove Input Shadows on iPad
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none;
  }
}

// TABLES

.table {

  // Bottom align for column headings
  > thead > tr > th {
    border-bottom-width: 1px;
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-bottom-width: 1px;
  }

}

.table-bordered {
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }
}


.progress {
  @include box-shadow(0 0 0 #000);
  border-radius: $border-radius-small;
  border: 1px solid $progress-bg;
  background-color: #fff;
  .progress-bar {
    @include box-shadow(0 0 0 #000);
  }
}

// Popover
// --------------------------------------------------

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid $gray-light;
  border-radius: $border-radius-small;
  .popover-title {
    border: 0;
  }
}


// NAV
.nav {
  &.nav-pills {
    li {
      a {
        color: gray;
      }
      &.area-selected a {
        color: green;
      }
    }
    .active > a {
      // color: white !important;
      // background-color: $brand-primary;
      color: $brand-primary !important;
      background-color: white !important;
      font-weight: bold;
    }
  }
}

// DROPDOWN
.dropdown-menu {
  border-radius: $border-radius-small;
}

.dropdown-header {
  color: #a1a2a3;
}

.navbar-top .navbar-nav > .active > a {
  color: #999;
  &:hover, &:focus {
    color: #d1d2d3;
  }
}


.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666;
  &:hover, &:focus {
    color: #222;
  }
}
// spaced for material design
.navbar-nav {
  margin: 0 !important;
}

// CAROUSEL

.carousel {
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-control {
    &.left,
    &.right {
      background-image: none;
    }
    // Add support for font awesome
    em {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

// Angular UI.Bootstrap
.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
  border-bottom: 0;
}

// fix modal backdrop to work with bootstrap ui
// standard BS js uses a calculated height
.modal-backdrop {
  position: fixed;
  bottom: 0;
}

// fix for ie9 (dropdwon no showing)
.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important;
}

// allow font awesome to be placed instead of glyphicon in slider
$fa-var-chevron-down: "\f078";
$fa-var-chevron-left: "\f053";
$fa-var-chevron-right: "\f054";
$fa-var-chevron-up: "\f077";
$fa-var-folder-open: "\f07c";
.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.glyphicon-chevron-right:before {
    content: $fa-var-chevron-right;
  };
  &.glyphicon-chevron-left:before {
    content: $fa-var-chevron-left;
  };
  &.glyphicon-chevron-up:before {
    content: $fa-var-chevron-up;
  };
  &.glyphicon-chevron-down:before {
    content: $fa-var-chevron-down;
  };
  &.glyphicon-folder-open:before {
    content: $fa-var-folder-open;
  };

}

.vertical-align {
    display: flex;
    align-items: center;
}

// .table > tbody:first-child > tr:first-child td {
//   border-top: 1px solid #eee !important;
// }


[disabled] md-input-container > md-select {
    pointer-events: none;
}

[disabled] md-input-container > md-select .md-select-value {
    background-position: bottom -1px left 0;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    border-bottom-color: transparent;
    color: rgba(0,0,0,0.38);
    background-image: linear-gradient(90deg,rgba(0,0,0,0.38) 0,rgba(0,0,0,0.38) 33%,transparent 0);
}

.card-columns {
  column-count: 2;
  @media only screen and (max-width: $mq-tablet) {
    column-count: 1;
  }
}



// btn-block for mobile
@media (max-width: 767px) {
  .btn-xs-block {
    display: block;
    width: 100%; 
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-sm-block {
    display: block;
    width: 100%; 
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-md-block {
    display: block;
    width: 100%; 
  }
}
@media (min-width: 1200px) {
  .btn-lg-block {
    display: block;
    width: 100%;
  }
}