#season-map.full-map{
    height: 100%;
    // margin: -15px -15px 0px -15px;
}

.season-container {
    position: absolute;
    top: 125px;
    left: 0px;
    bottom: 0px;
    width: 300px;
    padding: 0;
    margin: 0;
    z-index: 999;
    pointer-events: none;
}

.menu-items {
  background-color: $sidebar-bg;

  .nav-heading {
    color: $sidebar-heading-color;
  }

  // Items
  .nav {
    > li {
      > a,
      > .nav-item {
        color: $sidebar-item-color;
        &:focus, &:hover {
          color: $sidebar-item-color-active;
        }
        // Item icon
        > em {
          color: $sidebar-icon-color;
        }
      }

      // Active item state
      &.active,
      &.open {
        &,
        > a,
        .nav {
          background-color: $sidebar-item-bg-active;
          color: $sidebar-item-color-active;
        }
        > a > em {
          color: $sidebar-icon-color-active;
        }
      }
      &.active {
        border-left-color: $sidebar-item-color-active;
      }
    }
  }
}

.leaflet-left {
    left: 370px !important;
    top: 5px !important;
}
.details-area {
    padding: 10px 15px;
}