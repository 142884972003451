.table-budget {
    td.md-cell {
        border-top: none !important;;
    }
    thead {
        tr{
            th {
                span {
                    color: #ffffff !important;
                }
            }
            
        }
        
    }
    th, td {
        border: solid 1px #ffffff;
        span {
            color: #4a4a4a;
        }
    }
    input{
        background: transparent;
        border: none;
        text-align: center;
        width: 80%;
        color: #4a4a4a;
    }
    
}
.table-budget.md-table td.md-cell:last-child, .table-budget.md-table th.md-column:last-child{
    padding: 0 24px !important;
}

.budget-type {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.budget-type, .total-type{
    background-color: #c8e6c9;
    width: 160px !important; 
    min-width: 160px !important;
    max-width: 160px !important;
}

.budget-sub-type, .total-sub-type {
    background-color: #e8f5e9;
}

.budget-sub-type, .budget-type {
    text-align: left !important;
}

.budget-sub-type {
    padding-left: 20px !important;
}
.td-table-inside {
    border: none !important;
    height: 100% !important;
}
.budget-quant, .budget-value, .budget-quant-sub-type, .budget-value-sub-type, .budget-name{
    .sf_pencil_edit, .fa-remove {
        color: #4a4a4a;
        display: none;
        float: right;
        margin-top: 4px;
    }
    &:hover {
        .sf_pencil_edit, .fa-remove {
            display: block;
        }
    }
    cursor: pointer;

}

.budget-name {
    input {
        color: #ffffff !important;
    }
}

.budget-quant, .budget-quant-sub-type, .column-total-quant{
    margin-right: 0.5px;
}

.budget-value, .budget-value-sub-type, .column-total-value {
    margin-left: 0.5px;
}

.budget-row {
    .budget-quant{
        background-color: #e4e2e2
    }
    .budget-value{
        background-color: #dcdee0
    }
    .budget-quant-sub-type{
        background-color: #f5f5f5;
    }
    .budget-value-sub-type{
        background-color: #f2f4f5;
    }
    .column-total{
        background-color: #81c784;
        span {
            color: #4a4a4a;
            font-weight: 500;
        }
    }
    .column-total-quant{
        background-color: #e0e0e0;
    }
    .column-total-value{
        background-color: #d0d8dc;
    }
}

.green-bg {
    background-color: #43a047;
}

.quantity-label{
    background-color: #43a047;
    margin-right: 1px;
}
.value-label{
    background-color: #43a047;
    margin-left: 1px;
}

.sub-head{
    width: 100px;
}

.cell-flex{
    height: 100% !important;
    padding: 0 !important;
    text-align: center;
    width: 300px !important; 
    min-width: 300px !important;
}

.column-flex50{
    padding: 5px;
}

.container-budget-chart-total {
    padding: 30px;
    background-color: #f5f5f5;
    box-shadow: 0 0.5px 3px 0 rgba(0, 0, 0, 0.1);
}

.container-budget-chart{
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 0.5px 3px 0 rgba(0, 0, 0, 0.1);
}
