/* ========================================================================
     Component: typo
 ========================================================================== */
 
/* Roboto @font-face */
//@import url(http://fonts.googleapis.com/css?family=Roboto:400italic,300,700,300italic,400);
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

body {
  font-family: "Roboto", sans-serif;
  //font-family: 'Lato', sans-serif;
  color: #929292;
}

h1, h2, h3, h4 {
  font-weight: bold;
}

.article {
  font-size: 20px;
  line-height: 32px;
}