body{
    font-family:Roboto, "Helvetica Neue", sans-serif !important;
}
h1, h2, h3, h4 {
    color: #929292;
}
.md-button{
    font-family:Roboto, "Helvetica Neue", sans-serif !important;
}
input.input-picture[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
        cursor: pointer;
    }
}

#property-map {
    height: 330px;
}

a.navbar-item {
    color: #ffffff !important;
    font-weight: bold;
    transition: all 300ms ease-in-out;

    &:hover {
        color: #ffffff !important;
        background: rgba(255, 255, 255, .2) !important;
    }
}

a.user-select {
    color: #ffffff !important;
    font-weight: bold;
    transition: all 300ms ease-in-out;
    padding: 0px !important;
    &:hover {
        color: #ffffff !important;
        background: rgba(255, 255, 255, .2) !important;
    }
}

a.user-select.dropdown-toggle {
    padding: 0 10px !important;

    .user-block {
        display: inline-block !important;

        .user-block-picture {
            margin: 0 !important;
        }
    }
}

.top-buttons {
    margin: 0 0 10px 0;
}

.title-label {
    font-size: 22px;
    margin-right: 10px;
    vertical-align: bottom;
    float: left;
}

.base-animation {
    transition: all 300ms ease-in-out;
}

.base-animation.ng-enter {
    opacity: 0;

    &.ng-enter-active {
        opacity: 1;
    }
}

.base-animation.ng-leave {
    opacity: 1;

    &.ng-leave-active {
        opacity: 0;
    }
}

.base-animation.ng-hide {
    opacity: 0;
}

.wizard-tip {
    transition: all 300ms ease-in-out 300ms;
    position: absolute;
    width: 100%;
    margin-left: -15px;
}

.wizard-tip.ng-hide {
    transition: all 300ms ease-in-out 0ms;
    opacity: 0;
    margin-top: 20px;
}


// Dashboard Season
.selected-area {
    &, &:visited, &:active, &:hover {
        background: rgba(0, 149, 62, 0.1) !important;
    }
}

.side-info {
    border-left: 1px solid #cfdbe2;
    padding-top: 15px; 
    margin-top: -15px;

    a {
        color: #929292;
    }
}

// Fixed side panel
.sidepanel-view-title {
    // position: fixed;
    position: absolute;
    width: 100%; 
    z-index: 9;
}

.sidepanel-view-layout-row {
    padding-top: 65px;
    height: 100%;
    overflow: hidden;
    margin: 0 -15px -15px -15px;
}

.sidepanel-view-main {
    position: relative;
    overflow-y: auto; 
    padding-top: 15px
}

.sidepanel-view-detail {
    position: relative;
    padding-top: 25px;
    overflow-y: auto;
    margin-top: 0;
}

// Accordion header click
uib-accordion h4.panel-title > a {
    display: block;
}

.budgetHistoryList {

    md-list-item.md-3-line {

        .md-button {
            border: none;

            &:not([disabled]):hover {
                background-color: rgba(158,158,158,0.1);
            }

            .md-button {
                border: rgba(158,158,158,0.2) 1px solid;
                border-bottom: none;

                &:not([disabled]):hover {
                    background-color: transparent;
                }
            }

            .md-list-item-inner {

                .md-list-item-text {
                    h3, h4 {
                        color: #888;
                    }
                }
            }

        }

        &:last-child .md-button .md-button {
            border-bottom: rgba(158,158,158,0.2) 1px solid;
        }

        &.selected {
            background-color: #ddd;
        }
    } 

}

.panel-primary {
    border-color: #099356;
    margin-bottom: 21px;

    .panel-heading * {
        color: #ffffff;
    }
}

table.dataTable tfoot th, table.dataTable tfoot td {
    border: none !important;
    padding: 10px 10px 6px 10px !important;
}

// tree areas
li.abn-tree-row.level-2.b-selected.active a {
    color: green !important;
}
li.abn-tree-row.level-2.b-selected a {
    color: green !important;
}

//md-data-table

table.md-table th.md-column, table.md-table th.md-column.md-active md-icon, md-table-pagination .label {
    color: #879998 !important;
    font-size: 14px !important;
}

md-select.md-table-select>.md-select-value {
    color: #879998;
    font-size: 14px;
    font-weight: bold;
}

.md-select-menu-container.md-pagination-select .md-text {
    font-size: 14px;
    font-weight: bold;
}

table.md-table th.md-column md-icon.md-sort-icon svg, table.md-table th.md-column.md-active md-icon.md-sort-icon svg, table.md-table th.md-column:hover md-icon.md-sort-icon svg {
    height: 16px !important;
    width: 16px !important;
    margin-top: 5px;
    transform-origin: 8px 13px;
}

.activity-fields .col-md-6, .activity-fields .col-md-3 {	
    height: 70px;	
}

// Absolute Map

h3.map-abs-title {
    position: absolute;
    width: 100%;
    z-index: 99999999;
}

.full-map.abs-map {
    height: 100%;
    margin: 0 0 0px -15px;

    .leaflet-top.leaflet-left, .leaflet-top.leaflet-right {
        top: 20px !important;
    }
}


// md-table

table.md-table {

    &:not(.md-row-select) {
        td.md-cell:nth-child(n+2):nth-last-child(n+2), th.md-column:nth-child(n+2):nth-last-child(n+2) {
            padding: 0 16px !important;
            white-space: normal;
        }
    }

    th.md-column md-icon {

        &.md-sort-icon {
            transform-origin: 8px 13px;
        }
    
        &:not(:first-child) {
            margin-left: 8px;
            margin-right: -32px;
        }
    }
}

.btn-default.btn-add {

    &, &:active:hover, &:active:focus, &:active.focus, &.active:hover, &.active:focus, &.active.focus {

        border-color: #4aab4e;
        color: #4CAF50;

        .btn-label {
            background: #4CAF50;
            color: #fff;
        }
    }
}

.customInputFile {
    display: none !important;
}

.customInputFileLabel {
    cursor: pointer;
    border-radius: 50%;
    display: block;
    width: 128px !important;
    height: 128px !important;
    overflow: hidden;
    position: relative;
    border: 1px solid #e1e2e3;
    margin-top: 10px;

    &::before {
        content: "Alterar Foto";
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 12px;
        text-align: center;
        padding: 4px 0 10px 0;
        transition: all 400ms ease-in-out 0ms;
        margin-bottom: -100%;
    }

    &:hover:before {
        margin-bottom: 0;
    }

    img {
        border: none !important;
    }
}

// Table icons
.md-cell md-icon {
    margin-top: 10px;
}

.user-cell {
    text-align: left !important;

    > div {
        display: inline-block
    }
}

.dashboard-top-box {
    height: 110px;
    overflow: hidden !important;
}

.title-btn {
    margin-top: 8px;
}

.ac-users .slimScrollDiv {
    overflow: hidden !important;
    overflow-y: auto !important;

    &, > scrollable {
        height: auto !important;
        margin-bottom: 0;
    }
}

.nm-b {
    margin-bottom: 0 !important;
}

.cg-busy {
    z-index: 9999 !important;
}

.main-panel-planning:after {
    content: '';
    display: block;
    clear: both;
}

.checkbox-permission {
    margin-bottom: 0;
}
.md-info-tooltip {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #099356 !important;
    line-height: 11px !important;
    font-weight: bold;
    margin: 0 !important;
    margin-left: 5px !important;
}

.md-tooltip-max-width {
    max-width: 400px;
    background-color:#F1f1f1 !important;
    color:#000 !important;
    font-size:14px !important;
    padding:15px !important;
    height: auto !important;
    white-space: normal !important;
    .md-content{
        background-color:#F1f1f1 !important;
    }
}
/* Resets */
.list-clear{
    padding-left:0;
    margin:0;
    li{
        list-style:none;
    }
}
fieldset{
    border-bottom:none;
}

/* videogular */
.videogular-container {
    width: 100%;
    
    height: 320px;
    margin: auto;
    overflow: hidden;
}

videogular .iconButton, [videogular] .iconButton {
    font-family: 'videogular' !important;
}

.iconButton.exit{
    margin-right: 100px;
}

/*# sourceMappingURL=main.css.map */

.popover[class*="tour-"] {
    min-width: 350px !important;
    z-index: 999999 !important;
}


.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.div-base {
    border-style: dashed;
    border-width: thin;
    background-color: white;
    border-color: $brand-green-strider;
    padding: 20px;
}

.legend-map {
    position: absolute;
    top: 85px;
    left: 370px;
    z-index: 9999;
    background-color: #FFFF;
    border-radius: 5px;
    padding: 7px;
    padding-right: 25px;
    width: 190px;
}

.filter-button.btn-group:hover>.dropdown-menu {
    display: block;
    width: 173px;
}

.clear-filter.dropdown-menu {
    margin-left: 6px; 
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 4px;
}

.mt-10 {
    margin-top: 10px;
}

.highcharts-axis-labels{
    text{
        color: $brand-green-strider !important;
        fill: $brand-green-strider !important;
        font-weight: bold;
    }
    
}

.popover.tour{
    box-shadow: 10px 10px 40px -10px #000000 !important;
}

.uk-notify-bottom-center {
    text-align: center;
}

.md-panel-outer-wrapper {
  z-index: 9999999999 !important;
}

.loading-iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999999999;
    margin-top: 65px;
}

.loading-iframe-hide {
    display: none
}