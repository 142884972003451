/* ========================================================================
   Component: ngwig
  ========================================================================== */


// Font Awesome 4.20 variables for 
// editor toolbar
// ----------------------------------- 

$editor-hg: 250px;

/**
 *   main wrapper for the editor
 *
 *  .ng-wig
 *
 */
.ng-wig {
  display: block;
  padding: 0;
  margin: 0;
  &, .nw-editor {
    min-height: $editor-hg;
  }
}


/**
 *  styling for toolbar and its items
 *
 *  .nw-toolbar
 *    &__item
 *
 */
.nw-toolbar {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: #6B7277;

  background: -webkit-linear-gradient(90deg, #ffffff 0%, #f9f9f9 100%);
  background:    -moz-linear-gradient(90deg, #ffffff 0%, #f9f9f9 100%);
  background:         linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
  border: 1px solid #CCCCCC;
  border-radius: 3px 3px 0 0;
}
  .nw-toolbar__item {
    display: inline-block;
    vertical-align: top;

    border-right: 1px solid #DEDEDE;
  }

  .nw-toolbar label {
      line-height: 30px;
      display: inline-block;
      padding: 0 6px 0 3px;
  }

  .nw-toolbar input[type=checkbox] {
      vertical-align: -3px;
      margin-right: -1px;
  }

/**
 *  styling for the editor part: source code (original textarea) and resulting div
 *
 *  .nw-editor
 *    &__src
 *    &__res
 *
 */
.nw-editor {
  display: block;

  border: 1px solid $gray-light;
  border-radius: 0 0 3px 3px;
  margin-top: 15px;
}
  .nw-editor__src {
    width: 100%;
    height: 100%;
    resize: none;
    border-color: $gray-light;
  }
  .nw-editor__res {
    width: 100%;
    height: 100%;
    body {
      color: #fff;
    }
  }

.ng-wig {
  .btn-group .btn-default {
    color: #777;
  }
}

/**
 *  styling & formatting of content inside contenteditable div
 *
 *  .nw-content
 *
 */
.nw-content {
  padding: 12px;
  margin: 0;

  font-family: sans;
  font-size: 14px;
  line-height: 24px;
  color: #616263;

}
  .nw-content h1 {
    margin: 0 0 6px 0;

    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
  }
  .nw-content p {
    margin: 0 0 12px 0;
  }
  .nw-content ul {
    padding: 0;
    margin: 0 0 12px 24px;
    list-style: disc;
  }
  .nw-content ol {
    padding: 0;
    margin: 0 0 12px 24px;
    list-style: decimal;
  }
  .nw-content li {
    padding: 0;
    margin: 0;
  }
  .nw-content a {
    text-decoration: underline;

    color: #3fae98; /* green */
  }
  .nw-content a:hover {
    text-decoration: none;
  }
  .nw-content bold,
  .nw-content strong {
    font-weight: 700;
  }
  .nw-content i,
  .nw-content italic {
    font-style: italic;
  }