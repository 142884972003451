.img-header {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 960px;
    .background-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url(../../../app/img/img-head.png);
        opacity: 1;
        background-position: 50% 50%;
        background-size: cover;

        @media only screen and (max-width: 450px) {
            background-position: 50% 0%;
            background-size: 1200px;
        }
    }
}

.base-logo {
    height: 57px;
}   

.stripe-head {
    position: absolute;
    overflow: hidden;
    display: block;
    top: 0;
    left: 0;
    width: 50%;
    height: auto;
    z-index: 1;

    @media only screen and (max-width: 450px) {
        left: -140px;
        width: 500px;
        height: 800px;
        max-width: none;
    }
    
}

.home {
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    z-index: 2;
    button {
        background-color: #099356;
        border-radius: 25px;
        color: #ffffff;
    }
    .login {
        @media only screen and (max-width: 450px) {
            font-size: 9px;
        }
    }
}
.sig-strider {
    margin-top: 30px;
    @media only screen and (max-width: 450px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 450px) {
    .nav-bar {
        margin-top: 50px;
    }
}

.header {
    margin-top: 150px;
    text-align: center;
    color: #ffffff;

    @media only screen and (max-width: 450px) {
        margin-top: 50px;
    }
    
    .title {
        max-width: 680px;
        font-size: 44.5px;
        font-weight: 900;
        font-style: normal;
        margin-bottom: 22px;

        @media only screen and (max-width: 450px) {
            font-size: 30px;
        }
    }
    .sub-title {
        max-width: 617px;
        font-size: 22px;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 44px;

        @media only screen and (max-width: 450px) {
            font-size: 16px;
        }
        
    }
    .register-button {
        button{
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 4.8px;
            text-align: center;
            color: #ffffff;
            @media only screen and (max-width: 1280px) {
                letter-spacing: 3px;
            }
            @media only screen and (max-width: 450px) {
                font-size: 17px;
            }
        }
        margin-bottom: 467px;
        @media only screen and (max-width: 450px) {
            margin-bottom: 267px;
        }
    }
}

.screen-base {
    position: absolute;
    top: 800px;
    right: 0px;
    z-index: 2;
    max-width: 70%;

    @media only screen and (max-width: 450px) {
        top: 600px;
        right: -50px;
        width: 400px;
        max-width: none;
    }
}

.sub-header {
    margin-top: 450px;
    font-size: 35px;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    color: #099356;

    @media only screen and (max-width: 450px) {
        font-size: 20px;
    }
    
    .title {
        max-width: 648px;
        font-weight: 900;
        font-style: normal;
        color: #3a3e3d;
    }
    .sub-title {
        max-width: 648px;
    }

    @media only screen and (max-width: 1500px) {
        margin-top: 350px;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: 100px;
    }

    @media only screen and (max-width: 800px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 450px) {
        margin-top: 150px;
    }

}
.line-vertical {
    margin-top: 23px;
    margin-bottom: 23px;
    width: 4px;
    height: 52px;
    background-color: #dedede;
    border: solid 3px #dedede;
}
.costs {
    
    .item {
        width: 340px;
        height: 340px;
        z-index: 1;
        background-repeat: no-repeat;
        background-image: url(../../../app/img/triangle.png);
        background-size: 340px 340px;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        text-align: center;
        color: #ffffff;
        padding: 75px;
        @media only screen and (max-width: 450px) {
            background-size: 100%;
            width: 100%;
            height: 400px;
            padding: 60px;
        }
        @media only screen and (max-width: 350px) {
            font-size: 15px;
            height: 310px;
        }
        .title {
            font-weight: 900;
            font-style: italic;
        }
        .sub-title{
            margin-top: -50px;
            font-size: 15px;
            @media only screen and (max-width: 350px) {
                font-size: 12px;
                margin-top: -50px;
            }
        }
        .long-text{
            margin-top: -120px;
            @media only screen and (max-width: 350px) {
                margin-top: -90px;
            }
        }
    }
}
.green-shape{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -178px;
    z-index: -1;
    height: 600px;
    width: 100%;

    @media only screen and (max-width: 450px) {
        top: -850px;
        z-index: -1;
        height: 1420px;
        width: 1000px;
    }

    @media only screen and (max-width: 350px) {
        top: -600px;
        height: 1150px;
    }

    
}
.all-costs {
    text-align: left;
    color: #fbfbfb;
    font-style: italic;
    .title {
        margin-top: 60px;
        font-size: 60px;
        font-weight: 900;
        font-style: normal;
        line-height: 0.8543046357615894;
        @media only screen and (max-width: 450px) {
            font-size: 30px;
        }
    }
    .sub-title {
        margin-top: 20px;
        font-size: 25px;
        font-weight: 300;
        @media only screen and (max-width: 450px) {
            font-size: 20px;
        }
    }
    img {
        max-width: 110%;
        margin-top: 30px;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
    }

    
}

.form {
    margin-top: 100px;
    @media only screen and (min-width: 450px) and (max-width: 1280px) {
        margin-top: 350px;
    }
    .form-container {
        width: 550px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.17);
        header {
            padding: 30px;
            background-color: #099356;
            font-size: 17px;
            color: #ffffff;
        }
        section {
            padding: 30px;
            background-color: #ffffff;
        }

        .field {
            margin-bottom: 20px;
        }

        .actions {
            button {
                font-size: 20px;
            }
        }
    }
    
}

.info {
    margin-top: 118px;

    @media only screen and (max-width: 450px) {
        margin-top: 50px;
    }
    
    .title {
        font-size: 40px;
        font-weight: 300;
        font-style: italic;
        line-height: 1.303030303030303;
        text-align: center;
        color: #099356;

        @media only screen and (max-width: 450px) {
            font-size: 20px;
        }
    }
    .sub-title {
        width: 430px;
        margin-top: 22px;
        font-size: 29.5px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.1864406779661016;
        text-align: center;
        color: #3a3e3d;
        .number {
            font-weight: 900;
        }
        @media only screen and (max-width: 450px) {
            font-size: 17px;
        }
    }
}

.footer {
    margin-top: 45px;
    margin-bottom: 50px;
}

.sig-footer-strider {
    margin-bottom: 10px;
}

.stripe-footer {
    position: absolute;
    overflow: hidden;
    display: block;
    bottom: -10px;
    left: 0;
    width: 80%;
    height: auto;
    z-index: -10;

    @media only screen and (max-width: 450px) {
        width: 100%;
        height: 550px;
    }
}