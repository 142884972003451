/* ========================================================================
   Component: uiselect
 ========================================================================== */


.ui-select-bootstrap>.ui-select-match {
  text-align: left;
}

.ui-select-toggle {
  font-size: 14px;
}

.btn-default-focus {
  box-shadow: 0 0 0 #000 !important;
  outline: 0 !important;
}

.ui-select-bootstrap {
  .dropdown-menu {
    top: 100%;
  }
}