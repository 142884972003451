/* ========================================================================
   Component: ngdialog
  ========================================================================== */

.ngdialog {
  z-index: 9001 !important;
  &.ngdialog-theme-default {

    .ngdialog-content {
      background-color: #fff !important;
      border-radius: $border-radius-base !important;
      font-family: inherit !important;
      font-size: inherit !important;
      line-height: inherit !important;
    }

  }
}
