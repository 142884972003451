/* ========================================================================
     Component: plugins
// - User this stylesheet to include single css styles and fixes
//   for plugins
 ========================================================================== */

@import '../bootstrap/bootstrap/mixins/_buttons';

/**
 * Bootstrap Slider
 */

$slider-handle-bg: $brand-primary;
$slider-track-border: $brand-primary;
$slider-selection-bg: $slider-track-border;
$slider-tooltip-color: #2c3037;

// Seyria bootstrap slider
.bs-slider {
  display: inline-block;
  .slider-handle,
  .slider-track,
  .slider-selection {
    background-image: none;
    box-shadow: 0 0 0 #000;
  }

  .slider-handle {
    background-color: #fff;
    border: 1px solid $slider-handle-bg;
    opacity: 1;
  }
  .slider-track {
    background-color: #fff;
    border: 1px solid $slider-track-border;
  }
  .slider-selection {
    background-color: $slider-selection-bg;
  }

  .slider {
    .tooltip.top .tooltip-arrow {
      border-top-color: $slider-tooltip-color;
    }
    .tooltip.right .tooltip-arrow {
      border-right-color: $slider-tooltip-color;
    }
    .tooltip-inner {
      background-color: $slider-tooltip-color;
    }
  }

  .slider-lg {
    width: 380px;
  }

  .slider-horizontal {
    min-width: 210px;
  }

}


// Reuse form contrl definition from BS
// as a mixin to avoid redeclaration
// and extend the styles in other components
@mixin form-control()
{
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(#{"border-color ease-in-out .15s, box-shadow ease-in-out .15s"});

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  @include placeholder();

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content
  }

}

body {

  /**
   * Chosen
   */


  // MULTIPLE

  $multiple-tag-bg: #f5f6f7;

  .chosen-container {
    width: 100% !important;
    max-width: 100%;
  }

  .chosen-container-multi .chosen-choices {
    @include form-control();
  }

  .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    height: auto;
    padding: 0;
  }

  .chosen-container-multi .chosen-choices li.search-choice {
    margin: 0 5px 3px 0;
    background-color: $multiple-tag-bg;
    border: 1px solid darken($multiple-tag-bg, 5%);
    background-image: none;
  }

  .chosen-container .chosen-results li.highlighted {
    background-color: #a1a2a3;
    background-image: none;
  }
  .chosen-container .chosen-drop {
    border-color: #ddd;
  }
  .chosen-container .chosen-results li.group-result {
    color: #747576;
    letter-spacing: 0.02em;
  }
  // SINGLE
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-single {
    @include form-control();
  }

  .chosen-container-active .chosen-single,
  .chosen-container-active .chosen-choices {
      border: 1px solid $input-border-focus;
  }

  .chosen-container-single .chosen-drop {
    margin-top: -3px;
  }

  .chosen-container-single .chosen-single div {
    top: 6px;
    right: 5px;
  }

  .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    color: $input-color-placeholder;
  }

  /* Support for input groups */

  .input-group {
    .chosen-container:last-child > a {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    select:first-child + .chosen-container > a {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

  }

  /**
   * Filestyle
   */

  .bootstrap-filestyle {
    // max-width: 380px;
  }

  // Made plugin responsive
  [classyloader],
  [data-classyloader] {
    max-width: 100%;
  }

  // ui select
  .ui-select-bootstrap .ui-select-toggle {
    text-align: left;
  }

} // body

// Summernote

.summernote {
    + .note-editor {
        background-image: none !important;
        &.note-frame {
            border-color: $gray-light;
            &.fullscreen {
                margin-top: 0;
                @media only screen and (min-width: $mq-tablet) {
                    top: $navbar-hg;
                    left: $aside-wd;
                    right: 0;
                    width: auto !important;
                }
            }
        }
    }
}
// Summernote fullscreen layout support
.aside-collapsed  {
    .summernote + .note-editor.note-frame.fullscreen {
        @media only screen and (min-width: $mq-tablet) {
            left: $aside-wd-collapsed;
        }
    }
}

.aside-collapsed-text  {
    .summernote + .note-editor.note-frame.fullscreen {
        @media only screen and (min-width: $mq-tablet) {
            left: $aside-wd-collapsed-text;
        }
    }
}

.aside-float  {
    .summernote + .note-editor.note-frame.fullscreen {
        @media only screen and (min-width: $mq-tablet) {
            margin-left: 30px;
        }
    }
}
