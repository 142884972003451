/* ========================================================================
   Component: form-imgcrop
 ========================================================================== */
 
.imgcrop-area {
  width: 100%;
  height: 410px;
  overflow: hidden;
  background: $gray;
}

.imgcrop-preview {
  position: relative;
  width: 100%;
  height: 200px;
  margin:0 auto;
  background: $gray;
  text-align: center;
  &:after {
    content: attr(data-text);
    display: block;
    position: absolute;
    height: 50%;
    text-align: center;
    margin: auto;
    top: 0; left: 0;
    bottom: 0; right: 0;
    z-index: 0;
    color: $text-muted;
  }
  > img  {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }
}
