.strider-widget {

    .strider-widget-header {
        > h3 {
            // padding: 5px 0 0 34px;
            height: 30px;

            &.header-properties {
                background: transparent url('/app/img/icons/farm.svg') left center no-repeat scroll;
            }

            a {
                color: #929292;
                text-decoration: none;

                &:hover {

                }
            }
        }

        > h3, div {
            margin: 20px 0 10px 0;
        }
    }

    .strider-widget-block-link {
        position: relative;
        text-decoration: none;
        display: block;
        transition: all 200ms ease-in-out;

        &:hover {
            transform: scale(1.02);

            .strider-widget-item-block {
                background-color: #3C9F40;
            }
        }

        .strider-widget-item-block {
            overflow: hidden !important;
            height: 190px;
            transition: all 200ms ease-in-out;

            .h1 {
                font-size: 22px;
            }
            
            .col-xs-4 {
                height: 190px;
            }
        }
    }

    .panel.b.b-property {
        background: #4CAF50;
        color: #ffffff;

        &.b-img {
            background: linear-gradient(rgba(76, 175, 80, .5), rgba(76, 175, 80, 0.5)), url('/app/img/farm/890.png');
        }

        .label {
            color: #4CAF50;
            background: #ffffff;
        }

        h4, small {
            color: #ffffff;
        }

        .panel-footer {
            background: transparent;
        }
    }

    
}
.panel.b.b-season, .panel.b.b-property {
    background-size: cover;
    background-position: center center;
    position: relative;
    border: 0px;

    .col-md-6, .col-md-12 {
        padding-top: 60px;
        padding-left: 10px;
        height: 170px;
    }

    .label-white {
        background-color: #ffffff;
    }
    .label {
        i {
            color: rgba(0, 0, 0, 0.42);
        }
    }

    .pull-right {
        z-index: 99999;
        position: absolute;
        top: 0;
        right: 0;
        &.dropdown {
            margin-right: 10px;
            @media only screen and (max-width: $mq-tablet) {
                margin-top: 10px;
            }
        }
    
        > a {
            position: relative;
            width: 30px;
            height: 30px;
            padding-top: 6px;
            vertical-align: middle;
        }
    }
}

.panel.b.b-property {
    overflow: hidden !important;

     .col-md-6, .col-md-12 {
        position: relative;
        z-index: 9999;
        padding-top: 0;
        background: rgba(0, 149, 62, .7);
     }
}

.panel.b.b-supply {
    .col-md-6, .col-md-12 {
        padding-top: 20px;

        small {
            display: inline-block;
        }
    }
}


.panel.b.b-season.b-img.mt-sm:before, .panel.b.b-property.b-img.mt-sm:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
}

.panel.b.b-property.b-img.mt-sm:before {
    z-index: 999;
    background-color: rgba(0, 0, 0, .5);
}



// strider-base-select
strider-base-select {
    display: block;
}
.striderBaseSelectHeader {
    .header-searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0; 
    }
    .select-header {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        padding-left: 10.667px;
        height: 48px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: auto;

        position: fixed;
        margin-top: -48px;
        z-index: 999;
        background: #fff;
        width: 100%;
    }
    md-content._md {
        max-height: 240px; 
        padding-top: 48px;
    }
}

.budget-monitoring{
    .panel-heading {
        .pull-right {
            font-size: 14px; margin-top: 4px;
        }
    }
    .panel-body {
        overflow-y: auto;
    }

    .cell-flex {
        text-align: right !important;
        width: 290px !important; 
        min-width: 290px !important;
        border-left-color: #eee;
        border-left-style: solid;
        border-left-width: 1px;
    }
    .cell-flex-hide-planned{
        width: 145px !important; 
        min-width: 145px !important;
    }
}
.table-budget-monitoring[md-table]{
    width: 100%;
}

.table-budget-monitoring {
    th {
        text-align: center;
    }
    .type-row {
        background-color: #fafbfc;
    }
    .cell-sub-type {
        padding-left: 25px;
    }
    tbody{
        tr[md-row]{
            border-top: solid 1px #eeeeee;
        }
    }
}