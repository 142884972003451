/* ========================================================================
     Component: user-block
 ========================================================================== */

 // for use as navbar item
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important;
  width: 100% !important;
}

.user-block {
  display: flex;
  position: relative;
  padding: 15px 0 14px;
  cursor: pointer;
  @include clearfix();

//  background: url('../img/user-bg.jpg') no-repeat left top;
  background-size: cover;

  > .user-block-picture {
    position: relative;
    width: 35px;
    margin: 0 10px;
    > img {
      max-width: 100%;
      height: auto;
    }
  }

  .user-block-info {
    padding-top: 5px;
    white-space: nowrap;
    span {
      display: block;

    }

    .user-block-name {
      color: #585D66;
    }
    .user-block-role {
      font-size: 12px;
      color: #777B83;
    }
  }
}

.user-block-status {
  position: relative;
  > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff;
  }
}

// Adapt to collapsed mode
// .aside-collapsed,
// .aside-collapsed-text {

//   .user-block {

//     margin: 0;
//     text-align: center;

//     > .user-block-picture {
//       float: none;
//       margin: 0  auto;
//       width: 50px;

//     }
//     .user-block-info {
//       display: none;
//     }
//   }

//   .user-block-status {
//     > .circle {
//       bottom: 0;
//       right: 0;
//     }
//   }
// }
