md-input-container .md-errors-spacer {
    min-height: 0;
}

.md-chips {
    font-size: inherit;
    font-family: inherit;
    margin-bottom: 18px;
}

label {
    font-weight: normal !important;
}

.md-button.md-default-theme.md-primary.md-raised, .md-button.md-primary.md-raised, .md-button.md-default-theme.md-primary.md-fab, .md-button.md-primary.md-fab {
    color: #ffffff;
    background-color: rgb(7,146,86);
}

md-switch.md-default-theme.md-checked, md-switch.md-checked {
    .md-bar {
        background-color: rgba(0, 149, 62, 0.5);
    }
    .md-thumb {
        background-color: rgb(0, 149, 62)
    }
    .md-ink-ripple {
        color: rgb(0, 149, 62)
    }

    .md-container {
        margin-right: 0;
        margin-left: 8px;
    }
}

.md-datepicker-calendar-pane.md-pane-open {
    z-index: 9999 !important;
}



md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label, md-input-container:not(.md-input-invalid).md-input-has-value label {
    color: #929292;
}

md-input-container.md-default-theme label, md-input-container label, md-input-container.md-default-theme .md-placeholder, md-input-container .md-placeholder {
    color: #929292;
}

md-input-container.md-default-theme .md-input, md-input-container .md-input {
    color: #656565;
    border-color: #929292;
}

md-select.md-default-theme .md-select-value.md-select-placeholder, md-select .md-select-value.md-select-placeholder {
    color: #656565;
}


md-select.md-default-theme .md-select-value.md-select-placeholder, md-select .md-select-value.md-select-placeholder {
    color: #929292;
}

md-select.md-default-theme .md-select-value, md-select .md-select-value {
    border-bottom-color: #929292;
}

md-select.md-default-theme:not([disabled]):focus .md-select-value, md-select:not([disabled]):focus .md-select-value {
    color: #656565;
}

md-select-menu.md-default-theme md-content md-option, md-select-menu md-content md-option {
    color: #929292;
}

md-icon.md-default-theme, md-icon {
    color: #929292;
}

.btn.ng-hide {
    transition: all 0ms !important;
}

.spaced-childs > * {
    margin: 0 2px;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.invite-email {
    height: 33px; 
    border-radius: 4px;
}


// .propertySelectHeader .property-header-searchbox {
//     border: none;
//     outline: none;
//     height: 100%;
//     width: 100%;
//     padding: 0; }
// .propertySelectHeader .property-select-header {
//     box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
//     padding-left: 10.667px;
//     height: 48px;
//     cursor: pointer;
//     position: relative;
//     display: flex;
//     align-items: center;
//     width: auto; }
// .propertySelectHeader md-content._md {
//     max-height: 240px; }

.propertySelectHeader {
    .property-header-searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0; 
    }
    .property-select-header {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        padding-left: 10.667px;
        height: 48px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: auto; 
    }
    md-content._md {
        max-height: 240px;
    }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container, .md-autocomplete-suggestions {
    z-index: 9999 !important;
}

md-dialog {
    // width: 60%;
    border-radius: 4px !important;

    md-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: #099356 !important;

        h2 {
            color: #ffffff;
        }
    }

    .md-actions, md-dialog-actions {
        padding: 16px !important;
    }

}

.btn-block, .btn-xs-block, .btn-sm-block, .btn-md-block, .btn-lg-block {
    &.btn-labeled {
        text-indent: -12px;
        line-height: 32px;

        .btn-label {
            float: left;
            line-height: 20px;
            text-indent: 0;
        }
    }
}

.btn-xs.btn-block, .btn-xs.btn-xs-block, .btn-xs.btn-sm-block, .btn-xs.btn-md-block, .btn-xs.btn-lg-block {
    &.btn-labeled {
        text-indent: -12px;
        line-height: 1.6;
    }
}

.tree-selected {
  color: #FFAB40 !important; 
}

.tree-area-in-season {
    color: #84ca34 !important; 
  }

md-tooltip {
  .md-content {
    height: auto;
    max-width: 500px;
    white-space: initial;
  }
}

.filters {
    md-input-container {
        margin: 0 !important;
    }
    .md-datepicker-button {
        display: none;
    }
    ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore){
        margin-left: 12px !important;
        margin-bottom: 0px !important;
        right: auto !important;
        left: auto !important;
        width: auto !important;
    }
}

md-dialog.fullscreen-dialog {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
}